import React from "react"
import { Link } from "gatsby"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"
import Muted from "components/Typography/Muted.js"

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js"

// import team1 from "assets/img/faces/avatar.jpg"
// import team2 from "assets/img/faces/christian.jpg"
// import team3 from "assets/img/faces/kendall.jpg"
import yk_jpg from "assets/img/yk.jpg"
import blachford_jpg from "assets/img/blachford.jpg"
import iceland_jpg from "assets/img/iceland.jpg"

const useStyles = makeStyles(styles)

export default function TeamSection() {
  const classes = useStyles()
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  )
  return (
    <div className={classes.section}>
      <div>
        <Muted>* 2021日期預計2020三月公布 *</Muted>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={yk_jpg} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                ５天４夜
                <br />
                <small className={classes.smallTitle}>黃刀鎮</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  黃刀鎮五天四夜團介紹goes here
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Link to="/YKregular">
                  <Button color="primary" className={classes.margin5}>
                    2020
                  </Button>
                </Link>
                <Button disabled color="default" className={classes.margin5}>
                  2021
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={blachford_jpg} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Blachford原野小木屋
                <br />
                <small className={classes.smallTitle}>黃刀鎮</small>
              </h4>
              <CardBody>
                <p className={classes.description}>Blachford intro here</p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Link to="/YKblachford">
                  <Button color="primary" className={classes.margin5}>
                    2020
                  </Button>
                </Link>
                <Button disabled color="secondary" className={classes.margin5}>
                  2021
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={iceland_jpg} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                冰島，其他北歐國家
                <br />
                <small className={classes.smallTitle}>北歐</small>
              </h4>
              <CardBody>
                <p className={classes.description}> 冰島，其他北歐國家</p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button color="primary" className={classes.margin5}>
                  2020
                </Button>
                <Button disabled color="secondary" className={classes.margin5}>
                  2021
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
